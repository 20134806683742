import React, { useState, useEffect } from 'react';
import {Link} from "gatsby";
import PageIllustration from "../components/PageIllustration";

function PricingTables() {

  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [isBanner, setIsBanner] = useState(false);
  const [value, setValue] = useState(true);

  useEffect(() => {
    let classNames = document.documentElement.classList;

    console.log("pricing tables  ", classNames);
    if (classNames.contains("dark")) {
      setIsDarkTheme(true);
    }
    else {
      setIsDarkTheme(false);
    }

    if (classNames.contains("banner")) {
      setIsBanner(true);
    }
    else {
      setIsBanner(false);
    }

  }, [])

  const [priceOutput] = useState({
    plan1: {
      false: ['$', '55', '/mo'],
      true: ['$', '35', ' /lifetime']
    },
    plan2: {
      false: ['$', '85', '/mo'],
      true: ['$', '65', ' /lifetime']
    },
    plan3: {
      false: ['$', '135', '/mo'],
      true: ['$', '129', '/mo']
    }
  });

  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">

        <div className={`pt-16 pb-12 md:pt-16 md:pb-20 ${isBanner ? "mt-20 md:mt-8": ""}`}>

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-4">
            <h1 className="h1 mb-4 text-gray-800 dark:text-gray-300 text-4xl lg:text-5xl font-extrabold leading-tight tracking-tighter" data-aos="zoom-y-out"> 
            Commercial plans</h1>
            <p className="text-xl text-gray-500 dark:text-gray-400" data-aos="zoom-y-out" data-aos-delay="200">Get a Lightbox.js plan that fits your project's needs. 
            Use the coupon code below to get an exclusive discount.</p>
          
          </div>

         {/* <span>Get our exclusive early-bird discount! Get 30% off with the code ORDER30 at checkout.</span> */}

          {/* Pricing tables */}
          <div className={`${isDarkTheme ? "" : "hidden"} `}>

            {/* Pricing toggle */}
            {/* <div className="flex justify-center mb-16" data-aos="fGrab our exclusive early-bird discount! Get 30% off with the code ORDER30 at checkout.ade-up" data-aos-delay="400">
              <div className="inline-flex items-center">
                <div className="text-gray-500 font-medium mr-3">Billed Monthly</div>
                <div className="form-switch">
                  <input type="checkbox" name="pricing-toggle" id="pricing-toggle" className="sr-only" checked={value} onChange={() => setValue(!value)} />
                  <label className="bg-gray-600" htmlFor="pricing-toggle">
                    <span className="bg-gray-200" aria-hidden="true"></span>
                    <span className="sr-only">Enable to see yearly prices</span>
                  </label>
                </div>
                <div className="text-gray-500 font-medium ml-3">Billed Annually</div>
              </div>
            </div> */}

            <div className={`max-w-sm mx-auto grid gap-8 lg:grid-cols-2 lg:gap-6 items-start lg:max-w-3xl`}>

              {/* Pricing table 1 */}
              <div className="relative flex flex-col h-full p-6 bg-gray-800" data-aos="fade-up" data-aos-delay="700">
                <div className="mb-4 pb-4 border-b border-gray-700">
                  <div className="h4 text-purple-600 mb-1">Individual</div>
                  <div className="inline-flex items-baseline mb-2">
                    <span className="text-2xl md:text-3xl font-medium text-gray-400">{priceOutput.plan1[value][0]}</span>
                    <span className="h2 text-3xl font-extrabold leading-tight tracking-tighter">{priceOutput.plan1[value][1]}</span>
                    <span className="font-medium text-gray-400"> {priceOutput.plan1[value][2]}</span>
                  </div>
                  <div className="text-gray-400">Perfect for individual developers wishing to use Lightbox.js</div>
                </div>
                <div className="font-medium mb-3">Perfect for:</div>
                <ul className="text-gray-400 -mb-3 grow">
                  <li className="flex items-center mb-3">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>One developer</span>
                  </li>
                  <li className="flex items-center mb-3">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Unlimited projects</span>
                  </li>
                  <li className="flex items-center mb-3">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>E-mail and GitHub support</span>
                  </li>
                  <li className="flex items-center mb-3">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>For React JavaScript or React TypeScript projects</span>
                  </li>
                  <li className="flex items-center mb-3">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>All updates</span>
                  </li>
                  <li className="flex items-center mb-3">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Can be used in products for sale</span>
                  </li>
                </ul>
                <div className="border border-gray-700 p-3 mt-6">
                  <a className="btn-sm px-4 py-2 text-white bg-purple-600 hover:bg-purple-700 w-full" 
                  href="https://transactions.sendowl.com/products/78701966/1AB9A37D/view">Purchase</a>
                </div>
              </div>

              {/* Pricing table 2 */}
              <div className="relative flex flex-col h-full p-6 bg-gray-800" data-aos="fade-up" data-aos-delay="600">
                <div className="absolute top-0 right-0 mr-6 -mt-4">
                  <div className="inline-flex text-sm font-semibold py-1 px-3 mt-px text-green-600 bg-green-200 rounded-full">Most Popular</div>
                </div>
                <div className="mb-4 pb-4 border-b border-gray-700">
                  <div className="h4 text-purple-600 mb-1">Team & Company</div>
                  <div className="inline-flex items-baseline mb-2">
                    <span className="text-2xl md:text-3xl font-medium text-gray-400">{priceOutput.plan2[value][0]}</span>
                    <span className="h2 text-3xl font-extrabold leading-tight tracking-tighter">{priceOutput.plan2[value][1]}</span>
                    <span className="font-medium text-gray-400">{priceOutput.plan2[value][2]}</span>
                  </div>
                  <div className="text-gray-400">Perfect for teams or companies wishing to use Lightbox.js</div>
                </div>
                <div className="font-medium mb-3">Perfect for:</div>
                <ul className="text-gray-400 -mb-3 grow">
                  <li className="flex items-center mb-3">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Perfect for teams or companies wishing to use Lightbox.js</span>
                  </li>
                  <li className="flex items-center mb-3">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Unlimited projects</span>
                  </li>
                  <li className="flex items-center mb-3">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>E-mail and GitHub support</span>
                  </li>
                  <li className="flex items-center mb-3">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>For React JavaScript or React TypeScript projects</span>
                  </li>
                  <li className="flex items-center mb-3">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>All updates</span>
                  </li>
                  <li className="flex items-center mb-3">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Can be used in products for sale</span>
                  </li>
                </ul>
                <div className="border border-gray-700 p-3 mt-6">
                  <a className="btn-sm px-4 py-2 text-white bg-purple-600 hover:bg-purple-700 w-full" 
                  href="https://transactions.sendowl.com/products/78706141/D6FE1129/view">Join Waitlist</a>
                </div>
              </div>

              {/* Pricing table 3 */}
              {/* <div className="relative flex flex-col h-full p-6 bg-gray-800" data-aos="fade-up" data-aos-delay="800">
                <div className="mb-4 pb-4 border-b border-gray-700">
                  <div className="h4 text-purple-600 mb-1">Advanced</div>
                  <div className="inline-flex items-baseline mb-2">
                    <span className="text-2xl md:text-3xl font-medium text-gray-400">{priceOutput.plan3[value][0]}</span>
                    <span className="h2 text-3xl font-extrabold leading-tight tracking-tighter">{priceOutput.plan3[value][1]}</span>
                    <span className="font-medium text-gray-400">{priceOutput.plan3[value][2]}</span>
                  </div>
                  <div className="text-gray-400">Better insights for growing businesses that want more customers.</div>
                </div>
                <div className="font-medium mb-3">All features of Essential plus:</div>
                <ul className="text-gray-400 -mb-3 grow">
                  <li className="flex items-center mb-3">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>200 placeholder text commonly</span>
                  </li>
                  <li className="flex items-center mb-3">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Consectetur adipiscing elit</span>
                  </li>
                  <li className="flex items-center mb-3">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Excepteur sint occaecat cupidatat</span>
                  </li>
                  <li className="flex items-center mb-3">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Officia deserunt mollit anim</span>
                  </li>
                  <li className="flex items-center mb-3">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Voluptate velit esse cillum</span>
                  </li>
                  <li className="flex items-center mb-3">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Placeholder text commonly used</span>
                  </li>
                </ul>
                <div className="border border-gray-700 p-3 mt-6">
                  <a className="btn-sm px-4 py-2 font-medium inline-flex items-center justify-center border border-transparent rounded-sm leading-snug transition duration-150 ease-in-out font-medium inline-flex items-center justify-center border border-transparent rounded-sm leading-snug transition duration-150 ease-in-out text-white bg-purple-600 hover:bg-purple-700 w-full" href="#0">Start free trial</a>
                </div>
              </div> */}
            {/* Bottom infobox */}
            <div className="flex flex-col lg:flex-row justify-between items-center mt-16 lg:mt-6 lg:py-8 lg:border-t lg:border-b lg:border-gray-800">
              <div className="font-medium text-lg text-center lg:text-left mb-4 lg:mb-0">If your project is open-source, you 
              can request a free license key &gt;&gt;&gt; </div>
              <div>
                <Link to="/contact" className="btn-sm px-4 py-2 font-medium inline-flex items-center justify-center border border-transparent rounded-sm 
                leading-snug transition duration-150 ease-in-out text-white bg-purple-600 hover:bg-purple-700">
                  Contact us for a license key</Link>
              </div>
            </div>

            </div>


          </div>


          <div className={`${isDarkTheme ? "hidden" : ""} max-w-sm md:max-w-2xl xl:max-w-none 
          mx-auto grid gap-8 md:grid-cols-2 xl:grid-cols-2 xl:gap-6 items-start`}>

            {/* Pricing table 1 */}
            <div className="relative flex flex-col h-full py-5 px-6 rounded bg-white shadow-xl" data-aos="zoom-y-out" data-aos-delay="450">
              <div className="mb-4">
                <div className="text-lg font-bold mb-1">Individual</div>
                <div className="inline-flex items-baseline mb-2">
                  <span className="text-3xl font-bold">{priceOutput.plan1[value][0]}</span>
                  <span className="text-3xl font-bold">{priceOutput.plan1[value][1]}</span>
                  <span className="text-gray-600 pl-2">{priceOutput.plan1[value][2]}</span>
                </div>
                <div className="text-lg text-gray-800">Perfect for individual developers wishing to use Lightbox.js</div>
              </div>
              <ul className="text-gray-600 -mb-2 flex-grow">
                <li className="flex items-center mb-2">
                  <svg className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>One developer</span>
                </li>
                <li className="flex items-center mb-2">
                  <svg className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Unlimited projects</span>
                </li>
                <li className="flex items-center mb-2">
                  <svg className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>E-mail and GitHub support</span>
                </li>
                <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>For React JavaScript or React TypeScript projects</span>
                  </li>
                <li className="flex items-center mb-2">
                  <svg className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>All updates</span>
                </li>
                <li className="flex items-center mb-3">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Can be used in products for sale</span>
                  </li>
                {/* <li className="flex items-center mb-2">
                  <svg className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Dedicated support</span>
                </li> */}
              </ul>
              <div className="border-t border-gray-200 pt-5 mt-6">
                <a href="https://transactions.sendowl.com/products/78701966/1AB9A37D/view" className="btn-sm text-white bg-purple-600 hover:bg-purple-700 w-full font-medium inline-flex items-center justify-center border border-transparent rounded leading-snug transition duration-150 ease-in-out px-4 py-2 shadow">
                  Purchase</a>
              </div>
            </div>

            {/* Pricing table 2 */}
            <div className="relative flex flex-col h-full py-5 px-6 rounded shadow-xl border-2 border-purple-500" data-aos="zoom-y-out" data-aos-delay="450">
              <div className="absolute top-0 right-0 mr-5 p-3 -mt-5 bg-yellow-500 rounded-full">
                <svg className="w-4 h-4 fill-current text-white" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.145 5.05l-4.316-.627L8.898.513c-.338-.684-1.456-.684-1.794 0l-1.93 3.91-4.317.627a1.002 1.002 0 00-.554 1.707l3.124 3.044-.737 4.3a1 1 0 001.45 1.053L8 13.125l3.862 2.03c.728.381 1.59-.234 1.45-1.054l-.736-4.299L15.7 6.758a1.003 1.003 0 00-.555-1.708z" />
                </svg>
              </div>
              <div className="mb-4">
                <div className="text-lg font-bold mb-1">Team & Company</div>
                <div className="inline-flex items-baseline mb-2">
                  <span className="text-3xl font-bold">{priceOutput.plan2[value][0]}</span>
              <span className="text-3xl font-bold">{priceOutput.plan2[value][1]}</span>
                  <span className="text-gray-600 pl-2">/lifetime</span>
                </div>
                <div className="text-lg text-gray-800">Perfect for teams or companies wishing to use Lightbox.js.</div>
              </div>
              <ul className="text-gray-600 -mb-2 flex-grow">
                <li className="flex items-center mb-2">
                  <svg className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Perfect for teams or companies wishing to use Lightbox.js</span>
                </li>
                <li className="flex items-center mb-2">
                  <svg className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Unlimited projects</span>
                </li>
                <li className="flex items-center mb-2">
                  <svg className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>E-mail and GitHub support</span>
                </li>
                <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>For React JavaScript or React TypeScript projects</span>
                  </li>
                <li className="flex items-center mb-2">
                  <svg className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>All updates</span>
                </li>
                <li className="flex items-center mb-3">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Can be used in products for sale</span>
                  </li>
              </ul>
              <div className="border-t border-gray-200 pt-5 mt-6">
              {/* <button onClick={onSubscribeBtnClick} data-billsby-type="checkout" 
              className="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full pointer">Register Interest</button> */}
              <a href="https://transactions.sendowl.com/products/78706141/D6FE1129/view" 
              className="btn-sm text-white bg-purple-600 hover:bg-purple-700 w-full pointer font-medium inline-flex items-center 
              justify-center border border-transparent rounded leading-snug transition duration-150 ease-in-out px-4 py-2 
              shadow">Purchase</a>

              </div>


            </div>

            </div>
            <p className="flex mt-8 items-center mx-auto text-base px-3 py-4 w-4/5 text-gray-800 bg-green-100 border border-blue-200 rounded">
              <svg className="w-4 h-4 fill-current text-green-500 flex-shrink-0 mr-2" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 0a8 8 0 100 16A8 8 0 008 0zm2.629 11.618L8 10.236l-2.629 1.382.5-2.927-2.124-2.073 2.939-.427L8 3.528l1.314 2.663 2.939.427-2.126 2.073.502 2.927z" fill="#338CF5" fillRule="nonzero" />
              </svg>
              {/* <span>Get our exclusive early-bird discount! Get 30% off with the code ORDER30 at checkout.</span> */}
                <span>Get 20% off with the code OFFER20 at checkout. For a limited time only.</span>

            </p>
        </div>
        
              {/* Bottom infobox */}
              <div className="flex flex-col lg:flex-row justify-between items-center mt-4 md:mt-8 lg:mt-6 
              lg:py-8 lg:border-t lg:border-b lg:border-gray-800">
              <div className="font-medium text-lg text-center lg:text-left mb-4 lg:mb-0">If your project is open-source, you 
              can request a free license key &gt;&gt;&gt; </div>
              <div>
                <Link to="/contact" className="btn-sm px-4 py-2 font-medium inline-flex items-center justify-center border border-transparent rounded-sm 
                leading-snug transition duration-150 ease-in-out text-white bg-purple-600 hover:bg-purple-700">
                  Contact us for a license key</Link>
              </div>
              </div>
      </div>
    </section>
  );
}

export default PricingTables;
